.background-main-div{
    width: 100vw;
    height: 100vh;

    box-sizing: border-box;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-inline: 3rem;
    /* padding-top: 10rem;
    padding-bottom: 6rem;
    padding-inline: 14rem; */

    background: linear-gradient(135deg, #FB331A 4.08%, #F76C2E 99.07%), #FFE0CA;

    display: flex;
    align-items: center;
    justify-content: center;
}

.background-main-div
.main-content-div{
    width: 75vw;
    /* max-width: 88rem; */
    height: 70vh;
    /* max-height: 42rem; */
    box-sizing: border-box;

    background-color: #fff;

    border-radius: 3.5rem;

    position: relative;

    -webkit-box-shadow: 0px 10px 41px 7px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 10px 41px 7px rgba(0,0,0,0.25);
    box-shadow: 0px 10px 41px 7px rgba(0,0,0,0.25);
}

.background-main-div
.main-content-div
.main-logo-div{
    max-height: 200px;
    aspect-ratio: 1/1;
    position: absolute;

    top: -126px;
    z-index: 111;

    transform: translate(-50%, 0);
    left: 50%;
}

.background-main-div
.main-content-div
.main-logo-div
.main-logo{
    width: 100%;
    height: 100%;
}

.background-main-div
.background-main-div-photo{
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    /* padding-inline: 2rem; */
}

.background-main-div-photo
.image-container{
    width: calc(50% - 1px);
    height: 100%;
    box-sizing: border-box;
    padding: 5rem;
}

.background-main-div-photo
.image-container
.hero-image{
    width: 100%;
    height: 100%;
}

.background-main-div-photo
.divider{
    width: 2px;
    height: 80%;
    margin-top: 4rem;
    margin-bottom: 2rem;

    background-color: #FFC294;
}

.background-main-div-photo
.main-text-container{
    width: calc(50% - 1px);
    height: 100%;
    box-sizing: border-box;
    padding: 1rem;
    padding-top: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.background-main-div
.background-no-image-div{
    width: 100%;
    height: 100%;
}

@media (max-width: 1100px) {
    .background-main-div{
        width: 100vw;
        height: 100vh;
    
        box-sizing: border-box;
        padding-top: 0rem;
        padding-bottom: 0rem;
        padding-inline: 0rem;
    
        background: transparent;
    
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    
    .background-main-div
    .main-content-div{
        width: 100%;
        /* max-width: 88rem; */
        height: 100%;
        /* max-height: 42rem; */
        box-sizing: border-box;
        padding-top: 0rem;
    
        background-color: #fff;
    
        border-radius: 0rem;
    
        position: relative;
    
        -webkit-box-shadow: 0px 10px 41px 7px rgba(0,0,0,0.25);
        -moz-box-shadow: 0px 10px 41px 7px rgba(0,0,0,0.25);
        box-shadow: none;
    }
    
    .background-main-div
    .main-content-div
    .main-logo-div{
        display: none;
        width: 0;
        height: 0;
    }
   
    .background-main-div
    .background-main-div-photo{
        width: 100%;
        height: 100vh;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    
        box-sizing: border-box;
        padding-inline: 0rem;
        padding-bottom: 0rem;
    }
    
    .background-main-div-photo
    .image-container{
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        padding: 1rem;
        padding-top: 4rem;
    }
    
    .background-main-div-photo
    .image-container
    .hero-image{
        width: 80%;
        height: 80%;
    }
    
    .background-main-div-photo
    .divider{
        display: none;
    }
    
    .background-main-div-photo
    .main-text-container{
        width: 100%;
        height: 50%;
        box-sizing: border-box;
        padding: 2rem;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        border-radius: 3.125rem 3.125rem 0rem 0rem;
        background: linear-gradient(135deg, #FB331A 4.08%, #F76C2E 99.07%);
        box-shadow: 0px -10px 33.3px 0px rgba(0, 0, 0, 0.15);
    }
}