.loading-screen-main-div{
    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
}

.loading-screen-main-div
.loading-image-container{
    max-width: 25rem;
    
    aspect-ratio: 1/1;

    box-sizing: border-box;
    padding: 1rem;
    border-radius: 2.375rem;

    -webkit-box-shadow: 0px 10px 41px 19px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 10px 41px 19px rgba(0,0,0,0.25);
    box-shadow: 0px 10px 41px 19px rgba(0,0,0,0.25);
}

.loading-screen-main-div
.loading-image-container
.main-logo-desktop{
    width: 100%;
    height: 100%;
}

@media (max-width: 1100px) {
    .loading-screen-main-div
    .loading-image-container{
        box-shadow: none;
    }
}