.trip-history-main-div{
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    padding: 20px 30px;
    position: relative;
}

.trip-history-main-div
.main-head{
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.5px;
    text-align: center;

    color: #272727;
}

.trip-history-main-div
.driver-info-div{
    margin-bottom: 25px;
    width: 100%;
    max-height: 30%;
    box-sizing: border-box;
    padding: 20px 15%;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    box-shadow: 0px 4px 11.4px 0px #00000040;
}

.trip-history-main-div
.driver-name{
    font-size: 25px;
    font-weight: 700;
    line-height: 23px;
    text-align: left;

    color: #2B2B2B;
}

.trip-history-main-div
.ratings-div{
    margin-left: auto;
}

.trip-history-main-div
.trips-info-div{
    width: 100%;
    height: 50%;

    text-align: left;
}

.trip-history-main-div
.trips-info-div
.trip-nav-options
.trip-option{
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 5px 10px;

    font-size: 22px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;

    color: #2B2B2B;
}


.trip-history-main-div
.trips-info-div
.trip-nav-options
.selected{
    font-size: 22px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;

    border-bottom: 3px solid #373737;
}

.trip-history-main-div
.trips-info-div
.previous-trips-container{
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

    overflow-x: auto;
}

.trip-history-main-div
.trips-info-div
.previous-trips-container::-webkit-scrollbar{
    display: none;
}