.earned-today-card-div{
    width: 50%;
    height: fit-content;

    box-sizing: border-box;
    padding: 20px;

    border-radius: 20px;

    -webkit-box-shadow: 0px 10px 27px -11px rgba(40,201,40,1);
    -moz-box-shadow: 0px 10px 27px -11px rgba(40,201,40,1);
    box-shadow: 0px 10px 27px -11px rgba(40,201,40,1);
}

.earned-today-card-div
.earned-today-label{
    margin-bottom: 5px;
    font-size: 15.94px;
    font-weight: 500;
    line-height: 16.66px;
    text-align: left;

    color: #989898;
}

.earned-today-card-div
.earnings-today{
    margin-bottom: 7px;
    font-size: 21.74px;
    font-weight: 700;
    line-height: 16.66px;
    text-align: left;
}

.earned-today-card-div
.other-info{
    border-top: 0.72px solid #D0D0D0;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.earned-today-card-div
.other-info
.other-info-text{
    font-size: 10.14px;
    font-weight: 500;
    line-height: 16.66px;
    text-align: left;

    color: #9C9C9C;
}

.earned-today-card-div
.other-info
.other-info-text
span{
    display: block;
    font-size: 11.59px;
    font-weight: 600;
    line-height: 16.66px;
    text-align: left;

    color: #7E7E7E;
}

@media (max-width: 900px) {
    .earned-today-card-div{
        width: 100%;
    }
}