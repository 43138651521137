.driver-home-div{
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background: linear-gradient(270deg, #C9FFCC 17.22%, rgba(225, 255, 227, 0.33) 100%);
}

.driver-home-div
.customer-card{
    width: 400px;
    margin-left: 12%;
    z-index: 3;
}

.driver-home-div
.customer-card
.customer-card-image{
    width: 100%;
}

.driver-home-div
.map-background-div{
    overflow: hidden;
    position: absolute;
    height: calc(100% - 20px);
    top: 10px;
    right: 10px;

    border-radius: 56px 56px 56px 65px;
    border: 2px solid #FB371C;

    opacity: 0.3;

    box-shadow: 0px 4px 48.4px 0px rgba(0, 0, 0, 0.25);
}

.driver-home-div
.map-background-div
.map-background{
    height: 100%;
    transform: scale(1.2);
}

@media (max-width: 900px) {
    .driver-home-div{
        width: 100%;
        height: 100%;
    
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: linear-gradient(270deg, #C9FFCC 17.22%, rgba(225, 255, 227, 0.33) 100%);
    }
    
    .driver-home-div
    .customer-card{
        width: 400px;
        margin-left: 0%;
        z-index: 3;
    }
    
    .driver-home-div
    .customer-card
    .customer-card-image{
        width: 100%;
    }
    
    .driver-home-div
    .map-background-div{
        display: none;
        overflow: hidden;
        position: absolute;
        height: calc(100% - 20px);
        top: 10px;
        right: 10px;
    
        border-radius: 56px 56px 56px 65px;
        border: 2px solid #FB371C;
    
        opacity: 0.3;
    
        box-shadow: 0px 4px 48.4px 0px rgba(0, 0, 0, 0.25);
    }
    
    .driver-home-div
    .map-background-div
    .map-background{
        height: 100%;
        transform: scale(1.2);
    }
}