.driver-profile-main-div{
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.driver-profile-main-div
.empty-left-container{
    width: 35%;
    height: 100%;
}

.driver-profile-main-div
.right-main-div{
    width: 63%;
    height: 100%;

    box-sizing: border-box;
    padding: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}

.driver-profile-main-div
.right-main-div
.current-trip-card-container{
    width: 100%;
    height: fit-content;
}

.driver-profile-main-div
.right-main-div
.earnings-today-card{
    width: 100%;
    height: fit-content;
}

@media (max-width: 900px) {
    .driver-profile-main-div{
        width: 100%;
        height: 100%;
    
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    
    .driver-profile-main-div
    .empty-left-container{
        display: none;
    }
    
    .driver-profile-main-div
    .right-main-div{
        width: 100%;
        height: 100%;
    
        box-sizing: border-box;
        padding: 30px;
    
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 30px;
    }
    
    .driver-profile-main-div
    .right-main-div
    .current-trip-card-container{
        width: 100%;
        height: fit-content;
    }
    
    .driver-profile-main-div
    .right-main-div
    .earnings-today-card{
        width: 100%;
        height: fit-content;
    }
}