.previous-trip-card-div{
    /* height: 100%; */
    box-sizing: border-box;
    padding: 15px;

    border-radius: 16px;

    -webkit-box-shadow: 0px 10px 27px -11px rgba(40,201,40,1);
    -moz-box-shadow: 0px 10px 27px -11px rgba(40,201,40,1);
    box-shadow: 0px 10px 27px -11px rgba(40,201,40,1);
}

.previous-trip-card-div
.map-bg-image{
    width: 100%;
}

.previous-trip-card-div
.customer-profile-div{
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.previous-trip-card-div
.customer-profile-div
.profile-name{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    text-align: left;

    color: #2B2B2B;
}

.previous-trip-card-div
.customer-profile-div
.total-amt{
    font-size: 10px;
    font-weight: 300;
    line-height: 23px;
    text-align: left;
}

.previous-trip-card-div
.customer-profile-div
.total-amt
span{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;

    color: #2B2B2B;
}