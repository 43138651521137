.driver-earnings-main-div{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 2rem;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}

.driver-earnings-main-div
.earnings-left{
    width: calc(50% - 2rem);
    height: 100%;

    box-sizing: border-box;
    padding-inline: 3rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
}

.driver-earnings-main-div
.earnings-left
.earnings-title{
    /* font-family: Supermercado; */
    font-size: 35px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.5px;
    text-align: center;
}

.driver-earnings-main-div
.earnings-left
.wallet-balance-card{
    width: 100%;
    box-sizing: border-box;
    padding: 2rem 1.5rem;
    border-radius: 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;

    box-shadow: 0px 4px 15.8px 0px #00000040;
}

.driver-earnings-main-div
.earnings-left
.wallet-balance-card
.balance-left{
    text-align: start;
}

.driver-earnings-main-div
.earnings-left
.wallet-balance-card
.balance-left
.balance-title{
    margin-bottom: 0.8rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;

    color: #9C9C9C;
}

.driver-earnings-main-div
.earnings-left
.wallet-balance-card
.balance-left
.balance-number{
    font-size: 23px;
    font-weight: 700;
    line-height: 23px;
    text-align: left;

    color: #2B2B2B;
}

.driver-earnings-main-div
.earnings-left
.wallet-balance-card
.btns-div{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.driver-earnings-main-div
.earnings-left
.wallet-balance-card
.main-recharge-button{
    padding: 0.4rem 0.8rem;
    background: #00cc00 ;

    border: none;
    border-radius: 0.6rem;

    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;

    color: #FFFFFF;
}

.driver-earnings-main-div
.earnings-left
.wallet-balance-card
.main-withdraw-button{
    padding: 0.4rem 0.8rem;
    background: #cc0f0f;

    border: none;
    border-radius: 0.6rem;

    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;

    color: #FFFFFF;
}

.earnings-data-card{
    width: 100%;
    box-sizing: border-box;
    padding: 1.2rem 1rem;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    box-shadow: 0px 4px 15.8px 0px #00000040;
}

.earnings-data-card
.earnings-data-row{
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.earnings-data-card
.earnings-data-row
.earnings-label{
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    

    color: #9C9C9C;
}

.earnings-data-card
.bold-data-row
.earnings-label{
    color: #000000;

    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: left;
}

.earnings-data-card
.earnings-data-row
.earnings-num{
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;

    color: #9C9C9C;
}

.earnings-data-card
.bold-data-row
.earnings-num{
    color: #000000;

    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: left;
}

.earnings-right
.earnings-graph-card{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1rem 1rem;
    border-radius: 1rem;
/* 
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem; */

    box-shadow: 0px 4px 15.8px 0px #00000040;
}

.earnings-right
.earnings-graph-card
.earnings-time-period{
    margin-bottom: 0.6rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;

    color: #9C9C9C;
}

.earnings-right
.earnings-graph-card
.daily-earnings-div{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}

.earnings-right
.earnings-graph-card
.daily-earnings-div
.calender-btns{
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.4rem;
    cursor: pointer;
}

.earnings-right
.earnings-graph-card
.bar-chart-container{
    width: 100%;
    height: fit-content;
}

@media (max-width: 900px) {
    .driver-earnings-main-div{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 2rem 1rem;
        position: relative;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 0rem;
    }
    
    .driver-earnings-main-div
    .earnings-left{
        width: 100%;
        height: 100%;
    
        box-sizing: border-box;
        padding-inline: 3rem;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 1rem;
    }
    
    .driver-earnings-main-div
    .earnings-left
    .earnings-title{
        margin-bottom: 2rem;
        /* font-family: Supermercado; */
        font-size: 35px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0.5px;
        text-align: center;
    }
    
    .driver-earnings-main-div
    .earnings-left
    .wallet-balance-card{
        width: 100%;
        box-sizing: border-box;
        padding: 2rem 1.5rem;
        border-radius: 1rem;
    
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
    
        box-shadow: 0px 4px 15.8px 0px #00000040;
    }
    
    .driver-earnings-main-div
    .earnings-left
    .wallet-balance-card
    .balance-left{
        text-align: start;
    }
    
    .driver-earnings-main-div
    .earnings-left
    .wallet-balance-card
    .balance-left
    .balance-title{
        margin-bottom: 0.8rem;
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        text-align: left;
    
        color: #9C9C9C;
    }
    
    .driver-earnings-main-div
    .earnings-left
    .wallet-balance-card
    .balance-left
    .balance-number{
        font-size: 23px;
        font-weight: 700;
        line-height: 23px;
        text-align: left;
    
        color: #2B2B2B;
    }
    
    .driver-earnings-main-div
    .earnings-left
    .wallet-balance-card
    .main-withdraw-button{
        padding: 1rem 1.3rem;
        background: linear-gradient(0deg, #FF6B17, #FF6B17), linear-gradient(135.01deg, #FB331A 4.08%, #F76C2E 99.07%);
    
        border: none;
        border-radius: 0.6rem;
    
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        text-align: center;
    
        color: #FFFFFF;
    }
    
    .driver-earnings-main-div
    .earnings-data-card{
        width: 100%;
        box-sizing: border-box;
        padding: 1.2rem 1rem;
        border-radius: 1rem;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    
        box-shadow: 0px 4px 15.8px 0px #00000040;
    }
    
    .driver-earnings-main-div
    .earnings-data-card
    .earnings-data-row{
        width: 100%;
    
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .driver-earnings-main-div
    .earnings-data-card
    .earnings-data-row
    .earnings-label{
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        text-align: left;
        
    
        color: #9C9C9C;
    }
    
    .driver-earnings-main-div
    .earnings-data-card
    .bold-data-row
    .earnings-label{
        color: #000000;
    
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        text-align: left;
    }
    
    .driver-earnings-main-div
    .earnings-data-card
    .earnings-data-row
    .earnings-num{
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        text-align: left;
    
        color: #9C9C9C;
    }
    
    .driver-earnings-main-div
    .earnings-data-card
    .bold-data-row
    .earnings-num{
        color: #000000;
    
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        text-align: left;
    }
    
    .driver-earnings-main-div
    .earnings-right{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    
        padding: 3rem;
    }
    
    .driver-earnings-main-div
    .earnings-right
    .earnings-graph-card{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 1rem 1rem;
        border-radius: 1rem;
    /* 
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem; */
    
        box-shadow: 0px 4px 15.8px 0px #00000040;
    }
    
    .driver-earnings-main-div
    .earnings-right
    .earnings-graph-card
    .earnings-time-period{
        margin-bottom: 0.6rem;
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        text-align: center;
    
        color: #9C9C9C;
    }
    
    .driver-earnings-main-div
    .earnings-right
    .earnings-graph-card
    .daily-earnings-div{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4rem;
    }
    
    .driver-earnings-main-div
    .earnings-right
    .earnings-graph-card
    .daily-earnings-div
    .calender-btns{
        display: flex;
        align-items: center;
        justify-content: center;
    
        font-size: 1.4rem;
        cursor: pointer;
    }
    
    .driver-earnings-main-div
    .earnings-right
    .earnings-graph-card
    .bar-chart-container{
        width: 100%;
        height: fit-content;
    }
}